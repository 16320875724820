<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="CHECKLIST_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="checklistTypeCd"
            label='LBLTYPE'
            v-model="searchParam.checklistTypeCd"
          ></c-select>
        </div> -->
        <!-- 사용여부 -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="table"
          title="대분류"
          rowKey="ramChecklistCheckItemId"
          :columns="grid1.columns"
          :data="grid1.data"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-show="editable" label="LBLADD" :showLoading="false" icon="add" @btnClicked="add('FST')" />
              <c-btn
                v-show="editable && grid1.data.length > 0"
                :url="insertUrl"
                :isSubmit="isSave1"
                :param="grid1.data"
                mappingType="POST"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveItem('FST')"
                @btnCallback="saveCallback" />
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props }">
            <template>
              <q-chip
                v-if="props.row.editFlag !== 'C'"
                color="blue"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click.stop="() => { childEditable = true, rowClick(props.row) }">
                ▶
              </q-chip>
              <span v-else>
              </span>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="table"
          title="중분류"
          rowKey="ramChecklistCheckItemId"
          :columns="grid2.columns"
          :data="grid2.data"
        >
          
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'checkItemName'">
            <c-text-column
              v-if="!props.row.checkItemCd"
              :col="col"
              :props="props"
              label="중분류"
              class="tableHeaderText"
              name="userName"
              v-model="props.row.checkItemName">
            </c-text-column>
            <span v-else> {{ props.row.checkItemName }}</span>
          </template>
        </template>
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-show="editable && childEditable && popupData.type && popupData.type !== 'CTC0000020'" label="LBLADD" :showLoading="false" icon="add" @btnClicked="addPop(popupData.type)" />
              <c-btn v-show="editable && childEditable" label="LBLADDDIRECTLY" :showLoading="false" icon="add" @btnClicked="add('SEC')" />
              <c-btn
                v-show="editable && childEditable && grid2.data.length > 0"
                :url="insertUrl"
                :isSubmit="isSave2"
                :param="grid2.data"
                mappingType="POST"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveItem('SEC')"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>

<script>
import { uid } from "quasar";
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'checklist-master',
  data() {
    return {
      searchParam: {
        plantCd: '',
        useFlag: 'Y',
        checkItemTypeCd: null,
        checklistTypeCd: null,
      },
      grid1: {
        columns: [
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            required: true,
            name: 'checkItemName',
            field: 'checkItemName',
            label: '중분류',
            align: 'left',
            sortable: true,
            style: 'width:300px',
            type: 'custom',
          },
          {
            required: true,
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width:80px',
            type: 'check',
            true: 'Y',
            false: 'N',
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '정렬순서',
            align: 'center',
            sortable: true,
            style: 'width:80px',
            type: 'number',
          },
        ],
        data: [],
      },
      editable: true,
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      isSave1: false,
      isSave2: false,
      listUrl: '',
      insertUrl: '',
      searchUrl: '',
      deleteUrl: '',
      childEditable: false,
      popupData: {
        first: '',
        type : null,
        plantCd: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.ram.checklist.master.list.url;
      this.insertUrl = transactionConfig.ram.checklist.master.insert.url;
      this.deleteUrl = transactionConfig.ram.checklist.master.delete.url;
      // code setting
      this.getList();

      this.$comm.getComboItems('CHECKLIST_TYPE_CD').then(_result => {
        this.grid1.columns = [
          {
            required: true,
            name: 'plantCd',
            field: 'plantCd',
            label: '공사현장',
            align: 'center',
            style: 'width:150px',
            sortable: true,
            type: 'plant',
          },
          {
            required: true,
            name: 'checkItemName',
            field: 'checkItemName',
            label: '대분류',
            align: 'left',
            style: 'width:200px',
            sortable: true,
            type: 'text',
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '정렬순서',
            align: 'center',
            style: 'width:80px',
            sortable: true,
            type: 'number',
          },
          {
            required: true,
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:80px',
            sortable: true,
            type: 'check',
            true: 'Y',
            false: 'N',
          },
          {
            name: 'checklistTypeCd',
            field: 'checklistTypeCd',
            label: '팝업유형',
            align: 'center',
            style: 'width:140px',
            comboItems: _result,
            sortable: true,
            type: 'select',
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '중분류',
            style: 'width:70px',
            align: 'center',
            type: 'custom',
            sortable: false
          },
        ]
      })
    },
    getList() {
      this.searchParam.checklistLevelCd = '1' 

      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
      },);
    },
    add(type) {
      if (type === 'FST') {
        this.grid1.data.splice(0, 0, {
          plantCd: null, 
          ramChecklistCheckItemId: uid(),
          parentChecklistCheckItemId: null,
          checkItemName: '',
          checklistLevelCd: '1',
          checklistTypeCd: null,
          useFlag: 'Y',
          sortOrder: this.grid1.data.length + 1,
          regUserId: this.$store.getters.user.userId,
          chgUserId: this.$store.getters.user.userId,
          editFlag: 'C',
        })
      } else {
        this.grid2.data.splice(0, 0, {
          plantCd: null, 
          ramChecklistCheckItemId: uid(),
          parentChecklistCheckItemId: this.popupData.first,
          checkItemName: '',
          checklistLevelCd: '2',
          checklistTypeCd: null,
          useFlag: 'Y',
          sortOrder: this.grid2.data.length + 1,
          regUserId: this.$store.getters.user.userId,
          chgUserId: this.$store.getters.user.userId,
          editFlag: 'C',
        })
      }
    },
    rowClick(row) {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        parentChecklistCheckItemId: row.ramChecklistCheckItemId,
        checklistLevelCd: '2',
      }
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
        this.popupData.first = row.ramChecklistCheckItemId;
        this.popupData.type = row.checklistTypeCd;
        this.popupData.plantCd = row.plantCd
      },);
    },
    saveItem(type) {
      let checkItem = [];
      let isConti = true;
      let message = '';
      if (type === 'FST') {
        checkItem = ['plantCd', 'checkItemName', 'useFlag']
        this.$_.forEach(this.grid1.data, item => {
          this.$_.forEach(checkItem, check => {
            if (!item[check]) {
              isConti = false;
              return false;
            }
          })
        });
        message = '필수 입력값을 입력해 주세요. [사업장, 대분류, 사용여부]'
      } else {
        checkItem = ['checkItemName', 'useFlag']
        this.$_.forEach(this.grid2.data, item => {
          this.$_.forEach(checkItem, check => {
            if (!item[check]) {
              isConti = false;
              return false;
            }
          })
        });
        message = '필수 입력값을 입력해 주세요. [중분류, 사용여부]'
      }
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: message,
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (type === 'FST') {
              this.$_.forEach(this.grid1.data, _item => {
                _item.regUserId = this.$store.getters.user.userId;
                _item.chgUserId = this.$store.getters.user.userId;
              })
              this.isSave1 = !this.isSave1;
              this.getList();
            } else {
              this.$_.forEach(this.grid2.data, _item => {
                _item.regUserId = this.$store.getters.user.userId;
                _item.chgUserId = this.$store.getters.user.userId;
              })
              this.isSave2 = !this.isSave2
            } 
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.popupData.type === '2') {
        this.rowClick({
          ramChecklistCheckItemId: this.popupData.first,
          checklistTypeCd: this.popupData.type,
          plantCd: this.popupData.plantCd
        });
      } else {
        this.getList();
      }
    },
    addPop(type) {
      if (type === 'CTC0000005') {
        this.popupOptions.title = "작업 검색"; // 작업 검색
        this.popupOptions.param = {
          type: "multiple",
          searchType: "1", //1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 4:작업단계별 유해위험요인별 원인/결과
          plantCd: this.searchParam.plantCd,
        };
        this.popupOptions.target = () =>
          import(`${"@/pages/common/process/sopPop.vue"}`);
        this.popupOptions.width = "70%";
        this.popupOptions.visible = true;
        this.popupOptions.isFull = false;
        this.popupOptions.closeCallback = this.closeSopPopup;
      } else if (type === 'CTC0000001') {
        this.popupOptions.title = '공정 검색';
        this.popupOptions.param = {
          type: 'multiple',
          processLevelCd: '20',
          plantCd: this.searchParam.plantCd
        };
        this.popupOptions.target = () => import(`${'@/pages/common/process/processPop.vue'}`);
        this.popupOptions.width = "80%";
        this.popupOptions.visible = true;
        this.popupOptions.isFull = false;
        this.popupOptions.closeCallback = this.closeProcessPopup;
      } else if (type ==='CTC0000015') {
        // this.popupOptions.title = '유해인자유형 검색'; // 유해인자 검색
        // this.popupOptions.param = {
        //   type: 'multiple',
        // };
        // this.popupOptions.target = () => import(`${'@/pages/sai/wmr/harmfulFactorsPop.vue'}`);
        // this.popupOptions.width = '70%';
        // this.popupOptions.visible = true;
        // this.popupOptions.closeCallback = this.closeHazardPopup;
      } else if (type === 'CTC0000010'){
        this.popupOptions.title = "유해위험기계기구 검색"; 
        this.popupOptions.param = {
          type: "multiple",
        };
        // this.popupOptions.target = () =>
        //     import(`${"@/pages/sop/mim/hazardEquipment/hazardEquipmentClassPop.vue"}`);
        this.popupOptions.width = "60%";
        this.popupOptions.visible = true;
        this.popupOptions.isFull = false;
        this.popupOptions.closeCallback = this.closeEquipmentPopup;
      }
    },
    closeSopPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          if (this.$_.findIndex(this.grid2.data, { checkItemCd: _item.mdmSopId}) === -1) {
            this.grid2.data.splice(0, 0, {
              plantCd: this.popupData.plantCd, 
              ramChecklistCheckItemId: uid(),
              parentChecklistCheckItemId: this.popupData.first,
              checkItemCd: _item.mdmSopId,
              checkItemName: _item.sopName,
              checklistLevelCd: '2',
              checklistTypeCd: '1',
              useFlag: 'Y',
              sortOrder: this.grid2.data.length + 1,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            });
          }
        })
      }
    },
    closeProcessPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          if (this.$_.findIndex(this.grid2.data, { checkItemCd: _item.processCd}) === -1) {
            this.grid2.data.splice(0, 0, {
              plantCd: this.popupData.plantCd, 
              ramChecklistCheckItemId: uid(),
              parentChecklistCheckItemId: this.popupData.first,
              checkItemCd: _item.processCd,
              checkItemName: _item.processName,
              checklistLevelCd: '2',
              checklistTypeCd: '2',
              useFlag: 'Y',
              sortOrder: this.grid2.data.length + 1,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            });
          }
        })
      }
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          if (this.$_.findIndex(this.grid2.data, { checkItemCd: _item.hhmHazardousMachineryId}) === -1) {
            this.grid2.data.splice(0, 0, {
              plantCd: this.popupData.plantCd, 
              ramChecklistCheckItemId: uid(),
              parentChecklistCheckItemId: this.popupData.first,
              checkItemCd: _item.hhmHazardousMachineryId,
              checkItemName: _item.machineryName,
              checklistLevelCd: '2',
              checklistTypeCd: '3',
              useFlag: 'Y',
              sortOrder: this.grid2.data.length + 1,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            });
          }
        })
      }
    },
    closeHazardPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          if (this.$_.findIndex(this.grid2.data, { checkItemCd: _item.hazardCd}) === -1) {
            this.grid2.data.splice(0, 0, {
              plantCd: this.popupData.plantCd, 
              ramChecklistCheckItemId: uid(),
              parentChecklistCheckItemId: this.popupData.first,
              checkItemCd: _item.hazardCd,
              checkItemName: _item.hazardName,
              checklistLevelCd: '2',
              checklistTypeCd: '4',
              useFlag: 'Y',
              sortOrder: this.grid2.data.length + 1,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            });
          }
        })
      }
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.grid1.data = this.$_.reject(this.grid1.data, { ramChecklistCheckItemId: item.ramChecklistCheckItemId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
  }
};
</script>
